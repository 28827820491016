import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import useEndUserRoutes from '../../../routes/useEndUserRoutes';

/**
 *
 * returns the param value for given query param key.
 * @param queryKey
 */
export default function usePaginationCursor(
  queryKey: EndUserQueryParams = EndUserQueryParams.AFTER
): string {
  const { router } = useEndUserRoutes();
  return router.getUnwrappedQueryParam(queryKey, null);
}
