import useRepliesSortOrderProperties from '@aurora/shared-client/components/nodes/NodeProperties/useRepliesSortOrderProperties';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import {
  RepliesSortOrder,
  UserRepliesSortOrder
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import type {
  UserRepliesSortOrderQuery,
  UserRepliesSortOrderQueryVariables
} from '../../types/graphql-types';
import { MessageListMenuItem } from './MessageListMenu/MessageListMenu';
import userRepliesSortOrder from './MessageListMenu/UserRepliesSortOrder.query.graphql';

interface MessageSortMenuItem {
  /**
   * The loading state.
   */
  loading: boolean;
  /**
   * The user preferred setting for sort order.
   */
  sortMenuItem: MessageListMenuItem;
}

/**
 *
 * @param queryKey
 */
export default function useMessageSort(
  queryKey: EndUserQueryParams = EndUserQueryParams.SORT_MENU_QUERY_PARAMETER
): MessageSortMenuItem {
  const { router } = useEndUserRoutes();
  const { isAnonymous } = useRegistrationStatus();
  const { data: userData, loading: userLoading } = useQueryWithTracing<
    UserRepliesSortOrderQuery,
    UserRepliesSortOrderQueryVariables
  >(module, userRepliesSortOrder);

  const repliesSortOrder =
    userData?.self?.repliesSortOrderSetting?.sortOrder?.value ?? UserRepliesSortOrder.Default;

  const { loading: repliesSortOrderLoading, data: repliesSortOrderProperties } =
    useRepliesSortOrderProperties(module, undefined, {
      skip: repliesSortOrder !== UserRepliesSortOrder.Default
    });

  if (userLoading || repliesSortOrderLoading) {
    return {
      loading: true,
      sortMenuItem: getAsEnum<typeof MessageListMenuItem>(
        MessageListMenuItem.KUDOS_SUM_WEIGHT_DESC,
        MessageListMenuItem
      )
    };
  }

  const adminSortOrder =
    repliesSortOrderProperties?.coreNode?.repliesSortOrderProperties?.sortOrder;

  const selectedSortOrder =
    repliesSortOrder === UserRepliesSortOrder.Default ? adminSortOrder : repliesSortOrder;

  const sortOrderMapping = {
    [RepliesSortOrder.Likes]: MessageListMenuItem.KUDOS_SUM_WEIGHT_DESC,
    [RepliesSortOrder.PublishTime]: MessageListMenuItem.POST_TIME_ASC,
    [RepliesSortOrder.ReversePublishTime]: MessageListMenuItem.POST_TIME_DESC
  };

  const defaultSort = isAnonymous
    ? sortOrderMapping[adminSortOrder]
    : selectedSortOrder === UserRepliesSortOrder.Likes
    ? MessageListMenuItem.KUDOS_SUM_WEIGHT_DESC
    : selectedSortOrder === UserRepliesSortOrder.PublishTime
    ? MessageListMenuItem.POST_TIME_ASC
    : MessageListMenuItem.POST_TIME_DESC;

  const sortMenuItemString = router.getUnwrappedQueryParam(queryKey, defaultSort);
  return {
    loading: false,
    sortMenuItem: getAsEnum<typeof MessageListMenuItem>(
      sortMenuItemString,
      MessageListMenuItem,
      defaultSort
    )
  };
}
